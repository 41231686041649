import React from "react"

import { graphql } from "gatsby"

import Container from "components/container"
import Hero from "components/hero"
import Layout from "components/layout"
import SeparatorH2Style from "components/separator-h2-style"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default ({ data }) => (
  <Layout
    title="Contact"
    description="Get in touch by chat, phone or email and find our office addresses"
  >
    <Hero title={"Contact " + data.site.siteMetadata.title} />
    <Container size="narrow">
      <SeparatorH2Style>
        <h2>Customer Support</h2>
        <p>
          If you have any questions or need help, ask the driver or get in touch
          with one of the following methods.
        </p>
        <p>
          <strong>Chat:</strong> Use the chat bubble to the bottom right. We'll
          try to respond immediately during office hours (7am - 9pm) but if it's
          taking longer you can leave your email and we will get back to you.
        </p>
        <p>
          <strong>Email:</strong> Send an email to{" "}
          <a href="mailto:ride@ember.to">ride@ember.to</a> and we'll respond
          within 24 hours.
        </p>
        <p>
          <strong>Phone:</strong> Call us on{" "}
          <a href="tel:+441315104007">+44 (0)131 510 4007</a>. Our regular hours
          are 9am - 6pm, seven days a week, but in an emergency we are available
          24 hours per day. Note that you can't book a ticket on the phone.
        </p>
        <h2>Our Office</h2>
        <p>
          Our main office is in Edinburgh. The address is Ember Core Ltd, Argyle
          House, 3 Lady Lawson Street, Edinburgh, EH3 9DR (
          <a
            href="https://goo.gl/maps/XQ8AzvqMefimmPPq8"
            target="_blank"
            rel="noopener noreferrer"
          >
            map pin for entrance
          </a>
          ). If you're visiting, call the person you're meeting when you arrive
          so they can let you in.
        </p>
      </SeparatorH2Style>
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
